import { format as formatFn, formatInTimeZone, OptionsWithTZ } from "date-fns-tz";
import { enGB, enUS } from "date-fns/locale";
import { useCallback } from "react";
import { useUserContext } from "../context/UserContext";
import { HHMMSS, YYYYMMDD } from "../reclaim-api/types";
import { H12H24, TimeZone } from "../types";
import { satisfies } from "../utils/typescript";
import { useCallbackSafeRef } from "./useCallbackSafeRef";
import { useCaptureError } from "./useCaptureError";

export const TIME_FORMAT_MAP = satisfies<Record<string, { "24h"?: string; "12h"?: string }>>()({
  TIME_FORMAT: { "12h": "HH:mm:ss" }, // "03:30:00", "18:00:00"
  TIME_DISPLAY_FORMAT: { "24h": "H:mm", "12h": "h:mmaaa" }, // 1:00 pm | 13:00
  SHORT_TIME_DISPLAY_FORMAT: { "24h": "H:mm", "12h": "h:mm" }, // 1:00 | 13:00
  DAY_OF_WEEK_FORMAT: { "12h": "EE" }, // Tue
  DAY_OF_WEEK_TIME_FORMAT: { "24h": "EE 'at' H:mm", "12h": "EE 'at' h:mmaaa" }, // Tue at 1:00 pm | Tue at 13:00
  WEEKLESS_DATE_DISPLAY_FORMAT: { "12h": "PP" }, // Aug 10
  WEEKLESS_DATE_TIME_DISPLAY_FORMAT: { "24h": "PP, H:mm", "12h": "PP, h:mmaaa" }, // Aug 10, 1:00pm | Aug 10, 13:00
  MONTH_YEAR_DISPLAY_FORMAT: { "12h": "MMM yyyy" }, // Aug 2022
  FULL_MONTH_YEAR_DISPLAY_FORMAT: { "12h": "MMMM yyyy" }, // August 2022
  DATE_DISPLAY_FORMAT: { "12h": "EE, PP" }, // Tue, Aug 10
  FULL_DAY_DATE_DISPLAY_FORMAT: { "12h": "EEEE, PP" }, // Tuesday, Aug 10
  FULL_DAY_MONTH_DATE_DISPLAY_FORMAT: { "12h": "EEEE, PPP" }, // Tuesday, August 10
  SHORT_DATE_DISPLAY_FORMAT: { "12h": "EE, P" }, // Tue, 8/10
  SHORT_DATE_YEAR_DISPLAY_FORMAT: { "12h": "EE, P" }, // Tue, 8/10/2202
  DATE_YEAR_DISPLAY_FORMAT: { "12h": "EE, PP" }, // Tue, Aug 10, 2022
  FULL_DAY_DATE_YEAR_DISPLAY_FORMAT: { "12h": "EEEE, PP" }, // Tuesday, Aug 10, 2022
  WEEKLESS_DATE_YEAR_DISPLAY_FORMAT: { "12h": "PP" }, // Aug 10, 2022
  DATE_TIME_DISPLAY_FORMAT: { "24h": "EE, PP 'at' H:mm", "12h": "EE, PP 'at' h:mmaaa" }, // Tue, Aug 10th at 11:59 pm | Tue, Aug 10th at 23:59
  DATE_YEAR_TIME_DISPLAY_FORMAT: { "24h": "EE, PP 'at' H:mm", "12h": "EE, PP 'at' h:mmaaa" }, // Tue, Aug 10th, 2022 at 11:59 pm | Tue, Aug 10th, 2022 at 23:59
  SHORT_DATE_TIME_FORMAT: { "24h": "P H:mm", "12h": "P h:mmaaa" }, // 10/15 1:00pm | 10/15 13:00pm
  WEEKDAY_SHORT_DISPLAY_FORMAT: { "12h": "iii" }, // Mon
  DAY_DISPLAY_FORMAT: { "12h": "d" }, // 20
  HOUR_DISPLAY_FORMAT: { "24h": "H:00", "12h": "h a" }, // 1 pm | 13:00
  YEAR_DISPLAY_FORMAT: { "12h": "yyyy" }, // 2022
  ROUTER_DATE_FORMAT: { "12h": "yyyy-MM-dd" }, //  2022-12-01
  DAY_MONTH_FORMAT: { "12h": "P" } // 10/12
});

export type TimeFormat = keyof typeof TIME_FORMAT_MAP;

export const TIME_FORMAT_MAP_BY_CLOCK: Record<H12H24, Record<TimeFormat, string>> = {
  "12h": Object.entries(TIME_FORMAT_MAP).reduce(
    (map, [formatKey, obj]) => {
      map[formatKey] = obj["12h"];
      return map;
    },
    {} as Record<TimeFormat, string>
  ),
  "24h": Object.entries(TIME_FORMAT_MAP).reduce(
    (map, [formatKey, obj]) => {
      map[formatKey] = obj?.["24h"] || obj["12h"];
      return map;
    },
    {} as Record<TimeFormat, string>
  ),
};
// TODO: use extantMap with improvements
export const TIME_FORMATE_WITHOUT_YEAR: { [key in TimeFormat]?: boolean } = {
  WEEKLESS_DATE_DISPLAY_FORMAT: true,
  WEEKLESS_DATE_TIME_DISPLAY_FORMAT: true,
  DATE_DISPLAY_FORMAT: true,
  FULL_DAY_DATE_DISPLAY_FORMAT: true,
  FULL_DAY_MONTH_DATE_DISPLAY_FORMAT: true,
  SHORT_DATE_DISPLAY_FORMAT: true,
  DATE_TIME_DISPLAY_FORMAT: true,
  SHORT_DATE_TIME_FORMAT: true,
  DAY_MONTH_FORMAT: true,
};

export type DateTimeFormatterOptions = {
  timeZone?: TimeZone;
  timeTypeOverride?: H12H24;
};

export type DateTimeFormatHandler = {
  (date: Date, format: "TIME_FORMAT", options?: DateTimeFormatterOptions): HHMMSS;
  (date: Date, format: "ROUTER_DATE_FORMAT", options?: DateTimeFormatterOptions): YYYYMMDD;
  (date: Date, format: TimeFormat, options?: DateTimeFormatterOptions): string;
};

export type UseDateTimeFormatterReturnType = {
  format: DateTimeFormatHandler;
  shortenTime: (time: string) => string;
  formatMap: Record<TimeFormat, string>;
};

export const useDateTimeFormatter = (): UseDateTimeFormatterReturnType => {
  const [{ user }] = useUserContext();

  const timeType: H12H24 = !!user?.settings?.format24HourTime ? "24h" : "12h";

  const formatMap = TIME_FORMAT_MAP_BY_CLOCK[timeType];
  const { captureError } = useCaptureError();

  const format = useCallbackSafeRef(((date: Date, timeFormat: TimeFormat, options: DateTimeFormatterOptions = {}) => {
    const { timeZone, timeTypeOverride } = options;
    let userTimeFormat = TIME_FORMAT_MAP_BY_CLOCK[timeTypeOverride || timeType][timeFormat];
    const isFormatWithNoYear = TIME_FORMATE_WITHOUT_YEAR[timeFormat];
    const locale = user?.settings?.dateFieldOrder === "DMY" ? "other" : "us";

    if (timeFormat === "SHORT_DATE_YEAR_DISPLAY_FORMAT" && user?.settings?.dateFieldOrder === "YMD") {
      userTimeFormat = "EE, yyyy-MM-dd";
    }

    if (!userTimeFormat) {
      throw new Error("Unknown formatting. Please provide a valid TimeFormat");
    }

    const formatOptions: OptionsWithTZ = { locale: locale === "us" ? enUS : enGB, timeZone };

    let formatted = "";

    // Protect against bad dates attempting to be parsed
    try {
      formatted = timeZone
        ? formatInTimeZone(date, timeZone, userTimeFormat, formatOptions)
        : formatFn(date, userTimeFormat, formatOptions);
    } catch (error) {
      captureError(error);
    }

    /**
     * For "DMY" formatting with date-fns we need to strip the year off the "Long localized date" formating (P, PP). After crawling the
     * API and interwebs this seems to be an open issue with date-fns: https://github.com/date-fns/date-fns/issues/1946
     */

    if (timeFormat === "WEEKLESS_DATE_TIME_DISPLAY_FORMAT" && locale === "other") {
      formatted = formatted.replace(",", "");
    }

    // Strip the possible year displays "/****", ****" , " ****"
    return isFormatWithNoYear ? formatted.replace(/([A-Z])|\b(\/|\, | )[0-9]{4}\b/g, "$1") : formatted;
  }) as DateTimeFormatHandler);

  const shortenTime = useCallback((time: string) => (timeType === "12h" ? time.replace(":00", "") : time), [timeType]);

  return { format, shortenTime, formatMap };
};
